import { AxiosResponse } from "axios";
import { getAxiosInstances } from "../../../../utils/AppUtils";
import AxiosActions, { requestWithProgress } from "../../AxiosActions";

export const downloadFileById = (id: number, spinner: boolean = true, config = {}): Promise<AxiosResponse> => {
    return requestWithProgress(getAxiosInstances().V2, "get", `/file/${id}/download`, {}, { ...config, responseType: "blob" }, spinner);
};

export const getAllFilesByBoi = (instanceId: number, typeKey: string, relationKeys: string[], spinner: boolean = true, config = {}): Promise<AxiosResponse> => {
    const relations = relationKeys.map((key) => `relKeys=${key}`).join("&");
    return AxiosActions.get(getAxiosInstances().V2, `/file/all/${typeKey}/${instanceId}?${relations}`, { ...config }, spinner, true);
};
export const createFileVersion = (fileId: number, isCurrent: boolean, file: any, versionName?: string): Promise<AxiosResponse> => {
    const formData = new FormData();
    formData.append("fileNewVersion", file);
    return AxiosActions.post(
        getAxiosInstances().V2,
        `/file/${fileId}/version?current=${isCurrent}${versionName ? `&versionName=${versionName}` : ""}`,
        formData,
        {},
        true,
    );
};
export const queryByRelationship = (
    typeKey: string,
    instanceId: number,
    relKey: string,
    targetTypeKey: string,
    targetInstanceFilteringDTO: object,
    spinner: boolean = false,
    config = {},
    includeRelAttributes: boolean = false,
) => {
    return AxiosActions.post(
        getAxiosInstances().V2,
        `/query/${typeKey}/${instanceId}?relKey=${relKey}&targetTypeKey=${targetTypeKey}&includeRelAttributes=${includeRelAttributes}`,
        targetInstanceFilteringDTO,
        { ...config },
        spinner,
    );
};
export const deleteFile = (fileId: number | string): Promise<AxiosResponse> => {
    return AxiosActions.delete(getAxiosInstances().V2, `/file/${fileId}?hardDelete=true`, {}, true);
};

export const getAllRoles = (config): Promise<AxiosResponse> => {
    return AxiosActions.get(getAxiosInstances().V2, `access-control/role/all?limit=9999`, { ...config }, false);
};

export const uploadFileToRelation = (
    file: File,
    metadata: {
        typeKey: string;
        relKey: string;
        targetInstanceId: string | number;
        isPublic: boolean;
    },
    showSpinner: boolean = true,
    config: any = {},
): Promise<AxiosResponse> => {
    const formData = new FormData();
    formData.append("file", file);
    return AxiosActions.post(
        getAxiosInstances().V2,
        `/health/type/${metadata.typeKey}/${metadata.targetInstanceId}/relation/${metadata.relKey}/file/upload`, // ?fileName=${file.name}&isPublic=${metadata.isPublic}
        formData,
        {
            params: {
                fileName: file.name,
                isPublic: metadata.isPublic,
            },
            ...config,
        },
        showSpinner,
        true,
    );
};
