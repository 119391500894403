import { createRoot } from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";
import { StrictMode } from "react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import SentryProfiler from "./layouts/SentryProfiler";
import IntlAppLayout from "./layouts/IntlAppLayout";
import { registerAllPolyfills } from "./utils/Polyfills";

registerAllPolyfills();

const container = document.getElementById("root");
const root = createRoot(container!);
const queryClient = new QueryClient();

root.render(
    <StrictMode>
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                {process.env.NODE_ENV === "development" ? (
                    <>
                        <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-right" />
                        <IntlAppLayout />
                    </>
                ) : (
                    <SentryProfiler>
                        <IntlAppLayout />
                    </SentryProfiler>
                )}
            </QueryClientProvider>
        </Provider>
    </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
